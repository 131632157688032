import { lazy } from 'react'
import { RouteProps } from 'react-router'

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const Enquiry = lazy(() => import('../containers/Enquiry'))
const EnquiryDetail = lazy(() => import('../containers/EnquiryDetail'))
const Customer = lazy(() => import('../containers/Customer'))
const PatientInfo = lazy(() => import('../containers/PatientInfo'))
const AppointmentDetail = lazy(() => import('../containers/AppointmentDetail'))
const Appointment = lazy(() => import('../containers/Appointment'))
const Dashboard = lazy(() => import('../containers/Dashboard'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const Login = lazy(() => import('../containers/Login'))
const PatientDetails = lazy(() => import('../containers/OnboardingPatientDetails'))
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const ViewCustomer = lazy(() => import('../containers/Customer/ViewCustomer'))

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/dashboard',
    breadcrumb: 'Dashboard',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    exact: true,
    path: '/appointment',
    breadcrumb: 'Appointment Management',
    component: Appointment,
    title: 'Appointment Management'
  },
  {
    exact: true,
    path: '/appointment/:id',
    breadcrumb: 'Appointment Details',
    component: AppointmentDetail,
    title: 'Appointment Details'
  },
  {
    exact: true,
    path: '/patient/:id',
    breadcrumb: 'Patient Info',
    component: PatientInfo,
    title: 'Patient Info'
  },
  {
    exact: true,
    path: '/customer',
    breadcrumb: 'Customer Management',
    component: Customer,
    title: 'Customer Management'
  },
  {
    exact: true,
    path: '/customer/id',
    breadcrumb: 'View Customer',
    component: ViewCustomer,
    title: 'View Customer'
  },
  {
    exact: true,
    path: '/enquiry',
    breadcrumb: 'Enquiry Management',
    component: Enquiry,
    title: 'Enquiry Management'
  },
  {
    exact: true,
    path: '/enquiry/:id',
    breadcrumb: 'Enquiry Detail',
    component: EnquiryDetail,
    title: 'Enquiry Detail'
  },
  {
    exact: true,
    path: '/customer/id',
    breadcrumb: 'View Customer',
    component: ViewCustomer,
    title: 'View Customer'
  },
  {
    exact: true,
    path: '/customer/patient-onboarding-details',
    breadcrumb: 'Create Patient',
    component: PatientDetails,
    title: 'Create Patient'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    title: 'Login',
    path: '/',
    component: Login
  },
  {
    exact: true,
    title: 'Login',
    path: '/login',
    component: Login
  },
  {
    exact: true,
    title: 'Forgot Password',
    path: '/forgot-password',
    component: ForgotPassword
  },
  {
    exact: true,
    title: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
