import { IToast } from '@chakra-ui/core'
import {
  Book,
  HelpCircle,
  Home,
  LogOut,
  Server,
  User,
} from 'react-feather'
import { images } from '../theme'

// navigation constants
export type NavItem = {
  to: string
  title: string
  Icon: React.FC
  subMenu?: Array<{ to: string; title: string }>
}

export const NAV_ITEMS: NavItem[] = [
  {
    to: `/dashboard`,
    title: 'Dashboard',
    Icon: Home
  },
  {
    to: `/customer`,
    title: 'Customer',
    Icon: User
  },
  {
    to: `/appointment`,
    title: 'Appointment',
    Icon: Book
  },
  {
    to: `/service-and-product`,
    title: 'Service & Product',
    Icon: Server
  },
  {
    to: `/enquiry`,
    title: 'Enquiry',
    Icon: HelpCircle
  },
]

export const LOGOUT_NAV_ITEM: NavItem[] = [
  {
    to: `/`,
    title: 'Logout',
    Icon: LogOut
  }
]

// toast notification constants
export const SUCCESS_TOAST: IToast = {
  duration: 5000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}
