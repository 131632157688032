import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Redirect, Route, RouteProps, Switch } from 'react-router'

import { NAV_ITEMS } from '../constants'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'
import FillLoader from '../components/FillLoader'
import PageNotFound from '../containers/PageNotFound'
import SideBar from '../components/SideBar'

interface RouteType extends RouteProps {
  component: any
  title: string
}

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  return (
    <Route
      {...rest}
      render={(props) => <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
  </Route>
)

const Navigation = () => (
  <Router>
    <Suspense fallback={<FillLoader />}>
      <Switch>
        {PUBLIC_ROUTES.map((route) => {
          return <PublicRoute key={route.path} {...route} />
        })}
        <Route
          path="/"
          render={() => (
            <SideBar
              bg="brand.700"
              color="white"
              navItems={NAV_ITEMS}
              hoverColor="brand.500"
              accentColor="brand.50"
            >
              {/* {PUBLIC_ROUTES.map((route) => {
                return <PublicRoute key={route.path} {...route} />
              })} */}
              {PRIVATE_ROUTES.map((route) => {
                return <PublicRoute key={route.path} {...route} />
              })}
            </SideBar>
          )}
        />
        <Route render={() => PageNotFound} />
      </Switch>
    </Suspense>
  </Router>
)

export default Navigation
