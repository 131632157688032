import * as React from 'react'

import { AnimatePresence, motion, useAnimation, Variants } from 'framer-motion'
import { ColorProps } from 'styled-system'
import { Flex, IconButton } from '@chakra-ui/core'
import { useMediaQuery } from 'react-responsive'

import { images } from '../../theme'
import { NavItem } from '../../constants'
import { MenuCont, Overlay, RenderWrapper } from './styles'
import { useAppContext } from '../../context/AppProvider'
import Header from '../Header'
import SideBarItem from './SideBarItem'
import SideBarButton from './SideBarButton'
import { Text } from '../../typography'
import { Home } from 'react-feather'
import BottomTab from '../BottomTab'

type SideBarProps = ColorProps & {
  accentColor: string
  borderColor?: string
  closeOnNavigate?: boolean
  color: string
  hoverColor: string
  navItems: NavItem[]
  tooltipBg?: string
  tooltipColor?: string
}

const SideBar: React.FC<SideBarProps> = ({
  accentColor,
  bg,
  children,
  closeOnNavigate,
  color,
  hoverColor,
  navItems,
  tooltipBg,
  tooltipColor
}) => {
  const controls = useAnimation()
  const { drawerOpen, toggleDrawer } = useAppContext()
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const variants: Variants = {
    open: {
      x: 0,
      width: 250,
      transition: { staggerChildren: 0.05, delayChildren: 0.05, stiffness: 10, damping: 5 }
    },
    closed: {
      x: -250,
      width: 250,
      transition: {
        stiffness: 80,
        staggerDirection: -1,
        staggerChildren: 0.1
      }
    }
  }

  const handleNavItems = () => {
    const updatedNavItems = navItems
    return updatedNavItems
  }

  React.useEffect(() => {
    if (drawerOpen) {
      controls.start('open')
    } else {
      controls.start('closed')
    }
  }, [isTabletOrMobile, drawerOpen, controls])

  return (
    <>
      <MenuCont
        bg={bg}
        flexDir="column"
        animate={controls}
        variants={variants}
        alignItems="flex-start"
        // Calculate offset based on icon size
        iconOffset={(64 - 20) / 2}
        justifyContent="space-between"
        initial={{ width: drawerOpen ? 300 : 64 }}
      >
        <Flex
          pl="20px"
          width="100%"
          height="64px"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row-reverse"
          mb={10}
        >
          <IconButton
            color="#ff5700"
            background="transparent"
            size="xs"
            aria-label=""
            icon="close"
            onClick={toggleDrawer}
            display={["none",null,"block"]}
          />
          <Flex flex={1} mr={4} display={["none",null,"flex"]}>
            <AnimatePresence>
              {drawerOpen && (
                <Flex flexDirection="column" pt={5}>
                  <motion.img
                    width="80%"
                    height="auto"
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    src={images.poneaLogo}
                    style={{ alignSelf: 'flex-start' }}
                  />
                </Flex>
              )}
            </AnimatePresence>
          </Flex>
        </Flex>
        <Flex flexDirection="column" flexGrow={1} w="100%">
          {(handleNavItems() as NavItem[]).map((props) => (
            <SideBarItem
              color="ff5700"
              key={props.title}
              hoverColor={hoverColor}
              accentColor={accentColor}
              tooltipColor={tooltipColor}
              tooltipBg={tooltipBg}
              closeOnNavigate={closeOnNavigate}
              {...props}
            />
          ))}
        </Flex>
        <Flex flexDirection="column" w="100%">
          <SideBarItem
            color="ff5700"
            hoverColor={hoverColor}
            accentColor={accentColor}
            tooltipColor={tooltipColor}
            tooltipBg={tooltipBg}
            closeOnNavigate={closeOnNavigate}
            title="Settings"
            Icon={Home}
            to="/settings"
          />
          <SideBarItem
            color="ff5700"
            hoverColor={hoverColor}
            accentColor={accentColor}
            tooltipColor={tooltipColor}
            tooltipBg={tooltipBg}
            closeOnNavigate={closeOnNavigate}
            title="Logout"
            Icon={Home}
            to="/logout"
          />
        </Flex>
      </MenuCont>
      <RenderWrapper
        className="render-wrapper"
        pl={isTabletOrMobile ? 0 : drawerOpen ? '250px' : 4}
        pb={["10vh",null,0]}
      >
        <Header />
        {children}
        {isTabletOrMobile && (
          <>
            <Overlay
              onClick={toggleDrawer}
              initial={{ opacity: 0 }}
              pointerEvents={drawerOpen ? 'auto' : 'none'}
              animate={drawerOpen ? { opacity: 1 } : { opacity: 0 }}
            />
            <BottomTab />
          </>
        )}
      </RenderWrapper>
    </>
  )
}

export default SideBar

SideBar.defaultProps = {
  color: '#000000',
  bg: 'gray.900',
  hoverColor: 'gray.800',
  borderColor: 'gray.700',
  accentColor: 'cyan.500'
}
