import React from 'react'
import ReactDOM from 'react-dom'

import { css, Global } from '@emotion/core'
import { CSSReset, ThemeProvider } from '@chakra-ui/core'

import { AppProvider } from './context'
import { theme } from './theme'
import App from './App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <CSSReset />
        <Global
          styles={css`
            * {
              font-family: ${theme.fonts.body};
            }
            body {
              background: ${theme.colors.background};

              .active-nav-link {
                background: linear-gradient(to left, #eb1132, #f89419);
                color: white;
                .icon-wrap svg{
                  stroke: white;
                }
              }
              .active-bottom-nav-link {
                border-top-width: 3px;
                border-color: #f89419;
                color: #f89419;
                .icon-wrap svg{
                  stroke: #f89419;
                }
              }
            }
          `}
        />
        <App />
      </AppProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
